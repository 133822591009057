import axios from 'axios';

class ApiService {
    constructor(config, restaurantID) {
        this.config = config
        this.baseUrl = config.API_BASE_URL
        this.restaurantID = restaurantID
    }
    
    setRestaurantID(restaurantID) {
        this.restaurantID = restaurantID
    }

    async getConceptsAndEvents({lang}) {
        const params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'by_categories': 1
        }
        return this.call('/api.php', { method: 'get', cmd: 'getConceptsAndEvents',  params })
    }

    async getPersons({lang}) {
        const params = {
            'lang': lang,
            'restaurantid': this.restaurantID
        }
        return this.call('/api.php', { method: 'get', cmd: 'getPersons',  params })
    }
    async getChilds({persons, lang}) {
        const params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'persons': persons
        }
        return this.call('/api.php', { method: 'get', cmd: 'getChilds', params })
    }

    async getChildChairs({childs, lang}) {
        const params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'childs': childs
        }
        return this.call('/api.php', { method: 'get', cmd: 'getChildChairs', params})
    }

    async getDates({persons, area, numberOfChildren, lang, concept}) {
        let params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'concept': concept,
            'persons': persons
        }
        if (area != null) {
            params['area'] = area
        }
        if (numberOfChildren != null) {
            params['childs'] = numberOfChildren
        }
        return this.call('/api.php', { method: 'get', cmd: 'getDates', params })
    }

    async getTimes({concept,persons, date, area, numberOfChildren, lang}) {
        let params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'persons': persons,
            'concept': concept,
            'date': date
        }
        if (area != null) {
            params['area'] = area
        }
        if (numberOfChildren != null) {
            params['childs'] = numberOfChildren
        }
        return this.call('/api.php', { method: 'get', cmd: 'getTimes', params})
    }

    // TODO: what is reservation_length? check onlinebooking.php
    async getAreasAfterTime({persons, date, time, numberOfChildren, lang}) {
        let params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'persons': persons,
            'time': date + ' ' + time
        }
        if (numberOfChildren != null) {
            params['childs'] = numberOfChildren
        }
        return this.call('/api.php', { method: 'get', cmd: 'getAreasAfterTime', params })
    }

    async getAreas({lang}) {
        let params = {
            'lang': lang,
            'restaurantid': this.restaurantID
        }
        return this.call('/api.php', { method: 'get', cmd: 'getAreas', params })
    }

    async getOptions({date, time, lang}) {
        let params = {
            'lang': lang,
            'restaurantid': this.restaurantID,
            'time': date + ' ' + time
        }
        return this.call('/api.php', { method: 'get', cmd: 'getOptions', params })
    }

    async submitReservation(data) {
        let params = {
            'restaurantid': this.restaurantID,
        }
        for (let [key, value] of Object.entries(data)) {
            params[key] = value
        }
        return this.call('/api.php', { method: 'get', cmd: 'book', params })
    }

    async deleteReservation(bookingid, phone, lang) {
        let params = {
            'restaurantid': this.restaurantID,
            'lang': lang,
            'bookingid': bookingid,
            'phone': phone
        }
        return this.call('/api.php', { method: 'get', cmd: 'book_delete', params })
    }

    async submitReview({rating, comment, bookingIdentifierNo}) {
        let params = {
            'restaurantid': this.restaurantID,
            'rating': rating,
            'BookingIdentifierNo': bookingIdentifierNo
        }
        if (comment) {
            params['message'] = comment
        }
        return this.call('/api.php', { method: 'get', cmd: 'upload_rating', params})
    }

    async call(path, {method = 'get', cmd, params = {}}) {
        var queryParamseters = Object
            .keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&')
            

        if (queryParamseters != '')
            queryParamseters = "&" + queryParamseters

            
        const url = `${this.baseUrl}${path}?cmd=${cmd}${queryParamseters}`
        // console.log(`sending api call: ${url}`)
        return (await axios.request(url, method)).data
    }
}

export default ApiService