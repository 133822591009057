<template>
    <div 
        class="concept-card concept_buttons"
        :id="`concept_`+concept.id"
    >
        <div class="concept_container">
            <div class="image" v-if="concept.has_image">
                <img :src="concept.image_url" :alt="concept.name" class="concept-image"/> 
            </div> 
            <div class="text_container">
                <h2 class="concept_title">{{ concept.name }}</h2> 
                <p class="concept_description" v-html="formatMessage(concept.description)"></p>
            </div>
        </div>

        <span v-if="concept.is_concept == 1"
            class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="conceptSelected(concept.id, concept.name)"
        >
            <span class="ui-button-text">{{ lang.company['videre'] }}</span> 
        </span>
        <span v-if="concept.is_event == 1"
            class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="eventSelected(concept.id, concept.name)"
        >
            <span class="ui-button-text">{{ lang.company['videre'] }}</span> 
        </span>
        <div class="clearfix"></div>
        <hr v-if="!isLastItem">
    </div>
</template>


<script lang="js">

export default {
    name: 'Shit',
    data() {
        return {
        }
    },
    props: ['concept', 'isLastItem'],
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        formatMessage(msg) {
            return msg.replace(/\n/g, '<br>')
        },
        conceptSelected(id, name) {
            this.$emit('conceptSelected', id, name)
        },
        eventSelected(id, name) {
            this.$emit('eventSelected', id, name)
        }
    },
    created() {
    }
}

</script>