<template>
    <div>
        <!-- tak_for_afbestilling -->
        <p v-html="cancelMessage"></p>
    </div>
</template>

<script lang="js">

export default {
    name: 'CancellationCompleted',
    data() {
        return {
            
        }
    },
    props: ['restaurantID'],
    methods: {

    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        cancelMessage() {
            let message = this.lang.company['tak_for_afbestilling']
            if (message === undefined) {
                message = ""
            } else {
                message = message.replace("online_booking.php?restaurantId={ID}", this.bookingLink)
            }
            return message
        },
        bookingLink() {
            const baseURL = window.location.href.split('?')[0]
            let queryParams = ''

            if (this.$route.query.hasOwnProperty('lang')) {
                const language = this.$route.query.lang
                queryParams += `?lang=${language}`
            }
            if (this.$route.query.hasOwnProperty('restaurantId')) {
                const restaurantId = this.$route.query.restaurantId
                if (queryParams === '') {
                    queryParams += "?"
                } else {
                    queryParams += "&"
                }

                queryParams += `restaurantId=${restaurantId}`
            }
            return baseURL + queryParams
        },
    }
}
</script>


<style>
    
</style>